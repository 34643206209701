import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";
import styled, { injectGlobal } from "styled-components";
import CI from "@trackcode/ci";

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { name: "description", content: "Sample" },
            { name: "keywords", content: "sample, something" }
          ]}
        >
          <html lang="de" />
        </Helmet>
        <FullPage>{children}</FullPage>
      </>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;

const FullPage = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  @media print {
    position: static;
  }
`;

injectGlobal`
  * {
    box-sizing : border-box;
  }
  html, body {
    margin: 0;
    padding : 0;
    background: ${CI.color.neutral};
    height: 100%;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 15px;
    color: #333;
  }
  a {
    color: ${CI.color.brand};
    text-decoration: none;
    &:hover {
      color: ${CI.color.brand};
      text-decoration: underline;
    }
  }

  /*
   * Markdown Style
   */

  .markdown table {
    border-collapse: collapse;
  }
  .markdown table tr th,
  .markdown table tr td {
    text-align: left;
    vertical-align: bottom;
    border-bottom: 1px solid silver;
    padding: ${CI.space.sm}px ${CI.space.sm * 2}px;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
  .markdown table tr th {
    vertical-align: bottom;
  }
  .markdown table tr td {
    vertical-align: top;
  }
  .markdown h1 {
    font-size: 2em;
    font-weight: 600;
    margin-top: 28px;
    margin-bottom: 1pc;
    padding-bottom: .3em;
    border-bottom: 1px solid #eaecef;
  }
  .markdown h2 {
    font-size: 1.5em;
    font-weight: 600;
    margin-top: 28px;
    margin-bottom: 1pc;
    padding-bottom: .3em;
    border-bottom: 1px solid #eaecef;
  }
  .markdown h3 {
    font-size: 1.25em;
    font-weight: 600;
    margin-top: 28px;
    margin-bottom: 1pc;
    line-height: 1.25;
  }
  .markdown h4 {
    font-size: 1em;
    margin-top: 28px;
    margin-bottom: 1pc;
    font-weight: 600;
    line-height: 1.25;
  }
  .markdown h5 {
    font-size: .875em;
    margin-top: 28px;
    margin-bottom: 1pc;
    font-weight: 600;
    line-height: 1.25;
  }
  .markdown h6 {
    font-size: .85em;
    color: #6a737d;
    margin-top: 28px;
    margin-bottom: 1pc;
    font-weight: 600;
    line-height: 1.25;
  }
  .markdown blockquote {
    margin: 20px 0px;
    padding: 10px 20px;
    border-left: 5px solid ${CI.color.brand};
    color: ${CI.color.grayDark}
  }
  .markdown blockquote > p {
    margin: 0;
  }
  .markdown ul > li > p {
    margin-bottom: 6px;
  }
  .markdown hr {
    border: 0;
    border-bottom: 1px solid #eaecef;
  }
  
  /*
   * Prism Style
   */
  :not(pre) > code[class*="language-"],
  pre[class*="language-"] {
	  background: ${CI.color.grayLight};
  }
  .token.operator,
  .token.entity,
  .token.url,
  .language-css .token.string,
  .style .token.string {
    color: #9a6e3a;
    background: none;
  }
  .markdown :target:before {
    content: "";
    display: block;
    height: 27px; /* fixed header height*/
    margin: -27px 0 0; /* negative fixed header height */
    }
`;
