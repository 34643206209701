import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { Location } from "@reach/router";
import styled from "styled-components";
import { Box } from "@rebass/grid";
import { lighten } from "polished";
import classNames from "classnames";
import CI from "@trackcode/ci";

const isActive = (pathname, curr) => pathname.endsWith(curr);

export default ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        allDocsNavYaml {
          edges {
            node {
              title
              items {
                id
                title
              }
            }
          }
        }
      }
    `}
    render={({ allDocsNavYaml }) => {
      return (
        <Wrapper>
          <Location>
            {({ navigate, location: { pathname } }) =>
              allDocsNavYaml.edges.map(({ node }) => (
                <Section>
                  {node.title && <SectionTitle>{node.title}</SectionTitle>}
                  <>
                    {node.items.map(({ id, title }) => (
                      <LinkWrapper
                        className={classNames({
                          active: isActive(pathname, id)
                        })}
                        key={id}
                      >
                        <Link
                          className={classNames({
                            active: isActive(pathname, id)
                          })}
                          href={id}
                          onClick={e => {
                            e.preventDefault();
                            navigate(id);
                            return false;
                          }}
                        >
                          {title}
                        </Link>
                      </LinkWrapper>
                    ))}
                  </>
                </Section>
              ))
            }
          </Location>
        </Wrapper>
      );
    }}
  />
);

const Wrapper = styled(Box)`
  position: relative;
  overflow-y: auto;
  min-width: 300px;
  max-width: 300px;
  background: ${CI.color.grayLight};
  height: 100%;
  @media print {
    display: none;
  }
`;

const Section = styled.div`
  padding-top: ${CI.space.lg}px;
  &:last-child {
    padding-bottom: ${CI.space.lg}px;
  }
`;

const SectionTitle = styled.div`
  padding-right: ${CI.space.md}px;
  padding-left: ${CI.space.lg}px;
  padding-top: ${CI.space.sm}px;
  padding-bottom: 8px;
  color: ${lighten(0.125, CI.color.grayDark)};
  font-size: 14px;
`;

const LinkWrapper = styled.div`
  padding-right: ${CI.space.md}px;
  padding-left: ${CI.space.lg}px;
  margin-top: ${CI.space.sm / 2}px;
  margin-bottom: ${CI.space.sm / 2}px;
  padding-top: ${CI.space.sm / 2}px;
  padding-bottom: ${CI.space.sm / 2}px;
  &.active {
    padding-left: ${CI.space.lg - 5}px;
    border-left: 5px solid ${CI.color.brand};
  }
`;

const Link = styled.a`
  color: #333;
  &:hover {
    color: #333;
    text-decoration: none;
  }
  &.active {
    color: ${CI.color.brand};
    font-weight: 600;
  }
`;
