import React from "react";
import styled from "styled-components";
import CI from "@trackcode/ci";

export default ({ html }) => {
  return (
    <Wrapper className="markdown">
      <div dangerouslySetInnerHTML={{ __html: html }} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 898px;
  margin: ${CI.space.lg}px;
  padding-bottom: 30px;
`;