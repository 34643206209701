import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { Box } from "@rebass/grid";
import Layout from "../components/Layout";
import Sidebar from "../components/Sidebar";
import AppBar from "../components/AppBar";
import MarkdownPage from "../components/MarkdownPage";

export default function DocPage({
  data // this prop will be injected by the GraphQL query below.
}) {
  const { file } = data; // data.markdownRemark holds our post data
  const { childMarkdownRemark } = file;
  const { html } = childMarkdownRemark;
  return (
    <Layout>
      <AppBar />
      <Wrapper>
        <Sidebar />
        <ScrollBox flex={1}>
          <MarkdownPage html={html} />
        </ScrollBox>
      </Wrapper>
    </Layout>
  );
}

export const pageQuery = graphql`
  query($relativePath: String!) {
    file(relativePath: { eq: $relativePath }) {
      childMarkdownRemark {
        html
      }
    }
  }
`;

const Wrapper = styled.div`
  position: absolute;
  display: flex;
  top: 51px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  @media print {
    position: static;
    top: 0;
    overflow-y: visible;
  }
`;

const ScrollBox = styled(Box)`
  position: relative;
  overflow-y: auto;
  @media print {
    position: static;
    overflow-y: visible;
  }
`;
