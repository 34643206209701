import React from "react";
import styled from "styled-components";
import { AppBar } from "@trackcode/ui";
import TrackCodeLogoDark from "../images/trackcode-logo-dark.png";

export default () => (
  <Wrapper>
    <AppBar left={<Logo src={TrackCodeLogoDark} />} />
  </Wrapper>
);

const Wrapper = styled.div`
  width: 100%;
  z-index: 1;
  @media print {
    display: none;
  }
`;

const Logo = styled.img`
  height: 36px;
`;
